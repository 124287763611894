import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../../util/types';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import {
  AspectRatioWrapper,
  ResponsiveImage,
  Modal,
  IconRightArrow,
  IconBackArrow,
} from '../../../components';

import Slider from 'react-slick';

import css from './ListingImageGallery.module.css';
import '../../../styles/slick/slick.css';
import '../../../styles/slick/slick-theme.css';
import './image-gallery.css';

import { isArrayLength } from '../../../util/genericHelpers';

const MAX_LANDSCAPE_ASPECT_RATIO = 2; // 2:1
const MAX_PORTRAIT_ASPECT_RATIO = 4 / 3;

function RenderNextArrow({ className, onClick }) {
  return (
    <div className={className} onClick={onClick}>
      <IconRightArrow className={css.icon} />
    </div>
  );
}

function RenderPrevArrow({ className, onClick }) {
  return (
    <div className={className} onClick={onClick}>
      <IconBackArrow className={css.icon} />
    </div>
  );
}

const getFirstImageAspectRatio = (firstImage, scaledVariant) => {
  if (!firstImage) return { aspectWidth: 1, aspectHeight: 1 };

  const v = firstImage?.attributes?.variants?.[scaledVariant];
  const w = v?.width;
  const h = v?.height;
  const aspectRatio = w / h;

  return !!w && !!h && aspectRatio >= MAX_LANDSCAPE_ASPECT_RATIO
    ? { aspectWidth: 2, aspectHeight: 1 }
    : !!w && !!h && aspectRatio <= MAX_PORTRAIT_ASPECT_RATIO
    ? { aspectWidth: 4, aspectHeight: 3 }
    : !!w && !!h
    ? { aspectWidth: w, aspectHeight: h }
    : { aspectWidth: 1, aspectHeight: 1 };
};

const ListingImageGallery = props => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showAll, onShowAll] = useState(false);

  const {
    intl,
    images,
    imageVariants,
    thumbnailVariants,
    onManageDisableScrolling = () => false,
  } = props;

  const thumbVariants = thumbnailVariants || imageVariants;
  const firstImage = images?.[0];
  const { aspectWidth, aspectHeight } = getFirstImageAspectRatio(firstImage, imageVariants[0]);

  const items = images.map((img, i) => {
    return {
      original: '',
      alt: intl.formatMessage(
        { id: 'ListingImageGallery.imageAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbAlt: intl.formatMessage(
        { id: 'ListingImageGallery.imageThumbnailAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbnail: img.attributes?.variants?.[thumbVariants[0]],
      image: img,
    };
  });

  // Preload the first image (LCP) to improve performance
  const firstImageVariant = items[0]?.image.attributes.variants['scaled-large'];
  useEffect(() => {
    if (firstImageVariant?.url) {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = firstImageVariant.url;
      document.head.appendChild(link);
      return () => {
        document.head.removeChild(link);
      };
    }
  }, [firstImageVariant]);

  const imageSizesMaybe = {
    sizes: `(max-width: 1024px) 100vw, (max-width: 1200px) calc(100vw - 192px), 708px`,
  };

  const renderItem = (item, index) => (
    <AspectRatioWrapper
      key={index}
      width={aspectWidth || 1}
      height={aspectHeight || 1}
      className={css.itemWrapper}
    >
      <div
        className={css.itemCentering}
        role="button"
        onClick={() => {
          onShowAll(true);
          setActiveIndex(index);
        }}
      >
        <ResponsiveImage
          rootClassName={css.item}
          image={item.image}
          alt={item.alt}
          variants={imageVariants}
          {...imageSizesMaybe}
        />
      </div>
    </AspectRatioWrapper>
  );

  const settings = {
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    fade: true,
    infinite: true,
    lazyLoad: true,
    autoplay: false,
    arrows: true,
    initialSlide: activeIndex,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    pauseOnHover: true,
    nextArrow: <RenderNextArrow />,
    prevArrow: <RenderPrevArrow />,
  };

  const renderShowAllModal = (
    <Modal
      usePortal
      id="ListingImageGallery"
      modalTitle={`${intl.formatMessage({ id: 'ListingImageGallery.showAllPhotos' })} (${
        items?.length
      })`}
      isOpen={showAll}
      onClose={() => onShowAll(false)}
      onManageDisableScrolling={onManageDisableScrolling}
      isGalleryModal={true}
    >
      <div className={css.modalGallarySlider}>
        {items.length === 1 ? (
          items.map((item, index) => renderItem(item, index))
        ) : (
          <Slider className={css.gallarySec} {...settings}>
            {items.map((item, index) => renderItem(item, index))}
          </Slider>
        )}
      </div>
    </Modal>
  );

  if (items.length === 0) {
    return <ResponsiveImage className={css.noImage} image={null} variants={[]} alt="" />;
  }

  return (
    <>
      <div className={css.listingGallary}>
        {items.length > 0 && (
          <div
            className={css.listingGallaryItem}
            aria-label="View gallery"
            role="button"
            style={{ aspectRatio: '4 / 3', maxWidth: '800px', margin: '0 auto' }}
            onClick={() => onShowAll(true)}
          >
            <img
              src={items[0].image.attributes.variants['scaled-small']?.url}
              srcSet={`
        ${items[0].image.attributes.variants['scaled-small']?.url} 400w,
        ${items[0].image.attributes.variants['scaled-medium']?.url} 750w,
        ${items[0].image.attributes.variants['scaled-large']?.url} 1024w
      `}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 60vw, 800px"
              alt={items[0].alt}
              loading="eager"
              width="100%"
              height="auto"
              style={{ objectFit: 'cover', width: '100%', height: 'auto' }}
            />
          </div>
        )}

        {items.length > 1 && (
          <div className={css.listingGallaryItem}>
            {items.slice(1, 3).map((item, i) => {
              return (
                <div
                  key={i}
                  className={css.listingGallarySubItem}
                  role="button"
                  onClick={() => {
                    setActiveIndex(i + 1);
                    onShowAll(true);
                  }}
                >
                  <img
                    src={item.image.attributes.variants['scaled-small']?.url}
                    srcSet={`
    ${item.image.attributes.variants['scaled-small']?.url} 400w,
    ${item.image.attributes.variants['scaled-medium']?.url} 750w
  `}
                    sizes="(max-width: 768px) 50vw, (max-width: 1200px) 30vw, 30vw"
                    alt={item.alt}
                    loading="lazy"
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>

      <button
        className={css.openFullscreen}
        type="button"
        aria-label="View gallery"
        onClick={() => onShowAll(true)}
      >
        <FormattedMessage id="ListingImageGallery.showAllPhotos" />
      </button>

      {renderShowAllModal}
    </>
  );
};

ListingImageGallery.defaultProps = {
  rootClassName: null,
  className: null,
  thumbnailVariants: null,
};

ListingImageGallery.propTypes = {
  rootClassName: PropTypes.string,
  className: PropTypes.string,
  images: PropTypes.arrayOf(propTypes.image).isRequired,
  imageVariants: PropTypes.arrayOf(PropTypes.string).isRequired,
  thumbnailVariants: PropTypes.arrayOf(PropTypes.string),
  intl: intlShape.isRequired,
};

export default injectIntl(ListingImageGallery);
