import React from 'react';
import Icons from '../../components/Icons/Icons';
import classNames from 'classnames';
import css from './ListingPage.module.css';


function SectionSideNavigation() {
  return (
    <div className={css.sideNavigation}>
      <a className={classNames(css.sideNavLink, css.active)} href="#gallery" aria-label="Gallery section">
        <Icons name="gallary" />
      </a>
      <a className={classNames(css.sideNavLink)} href="#details" aria-label="Details section">
        <Icons name="details" />
      </a>
      <a className={classNames(css.sideNavLink)} href="#reviews" aria-label="Reviews section">
        <Icons name="reviews" />
      </a>
      <a href="#gallery" aria-label="Author section">
        <span className={css.progress}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">
            <circle cx="16" cy="16" r="15.9155" className={css.progress_bar__background}></circle>
            <circle
              cx="16"
              cy="16"
              r="15.9155"
              className={classNames(css.progress_bar__progress, css.js_progress_bar)}
              style={{ strokeDashoffset: ' 67.8433px' }}
            ></circle>
          </svg>
        </span>
      </a>
    </div>
  );
}

export default SectionSideNavigation;
