import React from 'react';
import { NamedLink } from '../../components';
import { FormattedMessage } from 'react-intl';
import css from './ListingPage.module.css';

function SectionBreadcrumbs({ title }) {
  return (
    <div className={css.listingBreadcrumbs}>
      <div className={css.contentWidth}>
        <div className={css.breadcrumbs}>
          <NamedLink name="LandingPage">
            <FormattedMessage id="ListingPage.homeCrumb" />
          </NamedLink>
          <NamedLink name="SearchPage">
            <FormattedMessage id="ListingPage.searchCrumb" />
          </NamedLink>
          <span className={css.current}>{title}</span>
        </div>
      </div>
    </div>
  );
}

export default SectionBreadcrumbs;
